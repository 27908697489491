'use client';

import React, { useEffect } from 'react';
import ErrorPage from '@/components/ErrorPage';
import { toastApi } from '@/lib/context/toasts';
import { ToastEnum } from '@/lib/context/toasts/toastApi';

const Error = ({ error }: { error: Error }) => {
    useEffect(() => {
        toastApi.add({
            message: error.message,
            variant: ToastEnum.error,
        });
    }, [error]);

    return <ErrorPage />;
};

export default Error;
